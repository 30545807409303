<template>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card v-if="dialog" >
            <!--<v-card-title></v-card-title>-->
            <v-row no-gutters style="border-bottom: 1px solid #dedede">
                <v-col>
                    <v-text-field v-model="from" dense hide-details
                                  flat solo>

                        <template v-slot:prepend-inner>
                            De:
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters style="border-bottom: 1px solid #dedede">
                <v-col>
                    <v-text-field v-model="to" dense hide-details
                                  flat solo>
                        <template v-slot:prepend-inner>
                            Para:
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters >
                <v-col>
                    <v-text-field v-model="subject" dense hide-details
                                  flat solo >
                        <template v-slot:prepend-inner>
                            Assunto:
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <tiptap-vuetify v-model="content"
                            :extensions="extensions"
                            :card-props="{ flat: true }"/>
            <v-card-actions class="pa-0">
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="enviar">
                            Enviar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
export default {
    name: "NovaMensagemDialog",
    components: { TiptapVuetify },
    data() {
        return {
            dialog: false,
            from: null,
            to: null,
            subject: null,
            rules: {
                required: (value) => !!value || 'Campo Obrigatório.',
                email: (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
            },
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        enviar(){
            this.$axios.post("/google/gmail/sendEmail", {
                from: this.from,
                to: this.to,
                subject: this.subject,
                body: this.content
            }).then(()=>{
                console.log("enviado")
            })
        }
    }
}
</script>

<style>
    .custom-text-field .v-input__slot {
        padding: 0px !important;
    }
    .tiptap-vuetify-editor__content{
        max-height: 200px;
    }
</style>