<template>
    <div style="overflow-y: unset;">
        <!--Toolbar-->
        <div class="grey lighten-4 elevation-1">
            <div class="pa-4 d-flex align-center">
                <v-select v-model="grupoSelecionado" :items="grupos" dense
                          item-text="name" item-value="id"
                          hide-details outlined
                          style="max-width: 200px" />
                <v-btn color="secondary" class="ml-2" depressed @click="novaMensagem">
                    <v-icon left>mdi-email-plus</v-icon>Nova mensagem
                </v-btn>
            </div>
            <v-progress-linear class="mt-n1" v-if="loadingEmail" indeterminate color="secondary"></v-progress-linear>
        </div>
        <v-row no-gutters class="pt-1">
            <!--Menu-->
            <v-col class="shrink" style="border-right: 1px solid #e5e5e5;">
                <v-navigation-drawer expand-on-hover >

                    <v-list nav shaped dense class="mt-2" min-width="250px">
                        <v-list-item-group v-model="selectedItem" mandatory active-class="secondary--text" >
                            <v-list-item value="entrada">
                                <v-list-item-icon><v-icon>mdi-inbox</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Entrada</v-list-item-title>
                                </v-list-item-content>
<!--                                <v-list-item-action>
                                    <v-list-item-action-text class="font-weight-bold">100</v-list-item-action-text>
                                </v-list-item-action>-->
                            </v-list-item>
                            <v-list-item value="saida">
                                <v-list-item-icon><v-icon>mdi-send</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Saída</v-list-item-title>
                                </v-list-item-content>
<!--                                <v-list-item-action>
                                    <v-list-item-action-text class="font-weight-bold">10</v-list-item-action-text>
                                </v-list-item-action>-->
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>
            </v-col>
            <!--Lista-->
            <v-col v-if="$vuetify.breakpoint.mobile ? !message : true" style="max-width: 450px; overflow-y: scroll; height: calc(100vh - 136px)">
                <!--Separar aqui em componentes-->
                <v-list two-line dense class="py-0">
                    <v-list-item-group v-model="selectedMessage" color="primary">
                        <template v-for="(message, index) in messages">
                            <v-list-item @click="" :class="isUnread(message.labels) ? 'v-list-item-unreaded' : ''">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">
                                        {{message.from}}
                                    </v-list-item-title>
                                    <v-list-item-title :class="isUnread(message.labels) ? 'font-weight-medium' : 'font-weight-regular'">
                                        {{message.subject}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle :class="isUnread(message.labels) ? 'font-weight-regular' : 'font-weight-light'">
                                        {{message.snippet}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>{{message.date | moment("from", "now")}}</v-list-item-action-text>

                                    <v-list-item-action-text v-if="message.totalAttachments > 0">
                                        <v-icon small class="mt-n1">mdi-attachment</v-icon>
                                        <span class=" ml-1">{{message.totalAttachments}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <div v-if="showInfiniteScroll" v-intersect="infiniteScroll" class="text-center" style="height: 30px">
                            <v-progress-circular size="20" width="2" indeterminate color="primary"></v-progress-circular>
                        </div>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <!--Email-->
            <v-col v-if="$vuetify.breakpoint.mobile ? message : true" id="email-content" class="email-content" style="overflow-y: scroll; height: calc(100vh - 136px)">
                <!--<router-view />-->
                <template v-if="message">
                    <div class="grey lighten-4 pa-4" >
                        <span class="body-2 d-block font-weight-medium">{{message.subject}}</span>
                        <v-row no-gutters>
                            <v-col>
                                <span class="caption d-block">de: {{message.from}}</span>
                                <span class="caption d-block">para: {{message.to}}</span>
                            </v-col>
                            <v-col class="shrink">
                                <span class="caption d-block text-no-wrap">{{message.date | moment("DD [de] MMM [de] YYYY HH:mm")}}</span>
                            </v-col>
                        </v-row>
                        <div class="mt-2" v-if="message.attachments && message.attachments.length > 0">
                            <span class="caption font-weight-medium">{{message.attachments.length}} anexos</span>
                            <div class="d-flex mt-1 overflow-x-auto pb-2">
                                <template v-for="attachment in message.attachments">
                                    <v-menu bottom left offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-card v-on="on" class="mr-4" min-width="200px" max-width="200px">
                                                <v-hover v-slot:default="{ hover }">
                                                    <v-list-item dense class="">
                                                        <v-list-item-avatar>
                                                            <v-icon :style="'opacity: '+(hover?'1':'0.6')"
                                                                    :color="getIconColor(attachment.filename)">
                                                                {{getIcon(attachment.filename)}}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title :title="attachment.filename">
                                                                {{attachment.filename}}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle >
                                                                {{bytesToHumanReadable(attachment.tamanho)}}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-hover>
                                            </v-card>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="downloadAttachment(message.id, message.id, attachment)">
                                                <v-list-item-title>Download</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="viewAttachment(message.id, message.id, attachment)">
                                                <v-list-item-title>Visualizar</v-list-item-title>
                                            </v-list-item>
                                            <!--<v-list-item @click="addAttachmentsToFiscalizacao(message.id, message.id, attachment)">
                                                <v-list-item-title>Adicionar na fiscalização</v-list-item-title>
                                            </v-list-item>-->
                                        </v-list>
                                    </v-menu>

                                </template>
                            </div>
                        </div>
                    </div>

                    <div class=" pa-4" v-html="message.body"></div>
                </template>
            </v-col>
        </v-row>
        <v-dialog v-model="viewAttachmentDialog" max-width="1200px" content-class="fill-height">
            <v-card style="height: inherit">
                <iframe :src="iframeSrc" width="100%" style="height: inherit" />
            </v-card>
        </v-dialog>
        <nova-mensagem-dialog ref="novaMensagemDialog" />
    </div>
</template>

<script>
// @ts-ignore
import page from '../../components/Page';
import {bytesToHumanReadable, getExtensionFile, getIcon, getIconColor} from "../../assets/FileUtils"
// @ts-ignore
import NovaMensagemDialog from "./NovaMensagemDialog";
import gql from "graphql-tag";

export default {
    name: "Index",
    components: {
        NovaMensagemDialog,
        page
    },
    data(){
        return{
            loadingEmail: false,
            loadingMessages: false,
            nextPageToken: null,
            selectedItem: null,
            selectedMessage: null,
            messages: [],
            message: null,
            viewAttachmentDialog: false,
            iframeSrc: null,
            grupoSelecionado: 'pessoal',
            grupos: [
                {name:'Pessoal', id: 'pessoal'},
                {name:'Fiscalização', id: 'fiscalizacao'},
                {name:'Projetos', id: 'projetos'},
                {name:'Atendimento', id: 'atendimento'},
                {name:'Financeiro', id: 'financeiro'},
            ],
            showInfiniteScroll: true,
        }
    },
    /*mounted() {
        console.log("mounted", this.selectedItem)
        this.getMessages(this.selectedItem)
    },*/
    apollo:{
        $subscribe:{
            novoEmail:{
                query: gql`subscription {
                    message:newEmailPublisher{
                        id
                        from:headerFrom
                        date:internalDate
                        subject:headerSubject
                        snippet
                        totalAttachments
                        unread
                        labels{
                            nome
                        }
                    }
                }`,
                result ({ data }) {
                    let message = data.message;
                    this.messages.unshift(message);
                },
            },
            emailAtualizado:{
                query: gql`subscription {
                        history:historyPublisher{
                            labelsAdded{
                                labelIds
                                message{
                                    id
                                    labelIds
                                }
                            }
                            labelsRemoved{
                                labelIds
                                message{
                                    id
                                    labelIds
                                }
                            }
                        }
                    }`,
                result ({ data }) {
                    let history = data.history;
                    //console.log("emailAtualizado.history", history)
                    this.processEmailAtualizadoResult(history)
                },
            },
        }
    },
    watch:{
        $route(to, from) {
            //console.log('$route', to, from)
            this.nextPageToken = null;
            if(to.params.grupo !== from.params.grupo){
                this.mudaGrupo(to.params.grupo)
                return
            }
            if(to.params.local !== from.params.local){
                this.mudaTipo(to.params.local)
                return
            }
            if(to.params.id){
                this.getEmail(to.params.id);
            }else{
                this.message = null;
            }
            this.showInfiniteScroll = true;
        },
        grupoSelecionado(value){
            //this.mudaTipo(value)
            this.$router.push({params:{grupo:value}});
            this.selectedItem = "entrada";
        },
        selectedItem(value){
            //this.mudaTipo(value)
            this.$router.push({params:{local:value}});
        },
        selectedMessage(value){
            console.log("selectedMessage", value)
            if(this.messages && this.messages.length > 0){
                console.log("aqui 1")
                //this.getEmail(this.messages[value].id);
                if(this.$route.params.id){
                    this.$router.push({params: {id:this.messages[value].id}})
                }else{
                    this.$router.push({params: {id:this.messages[value].id}})
                    /*if(!this.$vuetify.breakpoint.mobile){
                        this.$router.push({params: {id:this.messages[value].id}})
                    }else{
                        this.$router.push({params: {id:this.messages[value].id}})
                    }*/
                }

            }else{
                this.$router.push({params: {id:null}})
            }
        }
    },
    methods:{
        getIcon, getIconColor, getExtensionFile, bytesToHumanReadable,
        mudaTipo(tipo){
            this.selectedMessage = null;
            this.message = null;
            this.messages = []
            //this.$router.push({params:{local:tipo}});
            this.getMessages(this.nextPageToken)
        },
        mudaGrupo(grupo){
            this.selectedMessage = null;
            this.message = null;
            this.messages = []
            //this.$router.push({params:{local:tipo}});
            this.getMessages(this.nextPageToken)
        },
        getMessages(nextPageToken){
            this.loadingMessages = true;
            /*this.$apollo.watchQuery({
                query: gql`query($nextPageToken: String) {
                    emails(nextPageToken: $nextPageToken){
                        messages{
                            id
                            from:headerFrom
                            date:internalDate
                            subject:headerSubject
                            snippet
                            totalAttachments
                            unread
                        }
                        nextPageToken
                    }
                }`,
                variables: {
                    nextPageToken
                },
            })
            .subscribeToMore({
                document: gql`subscription {

                }`
            })*/


            /*this.$apollo.query({
                query: gql`query($nextPageToken: String) {
                    emails(nextPageToken: $nextPageToken){
                        messages{
                            id
                            from:headerFrom
                            date:internalDate
                            subject:headerSubject
                            snippet
                            totalAttachments
                            unread
                            labels{
                                nome
                            }
                        }
                        nextPageToken
                    }
                }`,
                variables: {
                    nextPageToken
                },
            }).then(result => {
                this.nextPageToken = result.data.emails.nextPageToken
                let messages = result.data.emails.messages.map((message) => {
                    message.from = this.formatEmail(message.from)
                    return message;
                })
                this.messages = this.messages.concat(messages)
            }).finally(()=>{
                this.loadingMessages = false;
            })*/

            this.$apollo.query({
                query: gql`query($tipo: String!, $group: String!, $nextPageToken: String) {
                    syncEmails(group: $group, tipo: $tipo, nextPageToken: $nextPageToken){
                        nextPageToken
                        messages{
                            id
                            from:headerFrom
                            date:internalDate
                            subject:headerSubject
                            snippet
                            totalAttachments
                            unread
                            labels{
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    group: this.grupoSelecionado,
                    tipo: this.selectedItem,
                    nextPageToken: nextPageToken
                },
            }).then(result => {
                this.nextPageToken = result.data.syncEmails.nextPageToken
                this.showInfiniteScroll = this.nextPageToken != null;

                let messages = result.data.syncEmails.messages.map((message) => {
                    message.from = this.formatEmail(message.from)
                    return message;
                })
                this.messages = this.messages.concat(messages)
            }).finally(()=>{
                this.loadingMessages = false;
            })
        },
        infiniteScroll(entries, observer, isIntersecting){
            if(isIntersecting && !this.loadingMessages){
                this.getMessages(this.nextPageToken)
            }
        },
        getEmail(gmailEmailId){
            this.loadingEmail = true;

            this.$apollo.query({
                query: gql`query($id: String!) {
                    email(id: $id){
                        id
                        from:headerFrom
                        to:headerTo
                        date:internalDate
                        subject:headerSubject
                        body:textHtml
                        payload
                        attachments{
                            attachmentId
                            filename
                            mimeType
                            tamanho
                        }
                    }
                }`,
                variables: {
                    id: gmailEmailId
                }
            }).then(result => {
                this.message = result.data.email
                this.message.to = this.formatEmail(this.message.to)
                this.message.from = this.formatEmail(this.message.from)
                this.message.body = this.replaceCidImages(result.data.email)
            }).finally(() => {
                document.getElementById("email-content").scrollTop = 0;
                this.loadingEmail = false;
            })
        },
        isUnread(labels){
            let result = labels.some(l => l.nome === 'UNREAD');
            return result;
        },
        async processEmailAtualizadoResult(history){
            if(history.labelsRemoved){
                let message = this.messages.find(m => m.id === history.labelsRemoved[0].message.id);
                if(message){
                    message.labels = history.labelsRemoved[0].message.labelIds.map(l => {
                        return {
                            nome: l
                        }
                    })
                }
            }

            if(history.labelsAdded){
                let message = this.messages.find(m => m.id === history.labelsAdded[0].message.id);
                if(message){

                    message.labels = history.labelsAdded[0].message.labelIds.map(l => {
                        return {
                            nome: l
                        }
                    })
                    //this.$set(message, 'labels', history.labelsAdded[0].message.labelIds)
                }
            }
        },
        formatEmail(email){
            return email
            //return email.split(',');
            /*return email.replaceAll(/"[',"].*?[',"]"/gm, substring => {
                let text = substring.substr(2, substring.length - 4);
                //console.log("formatEmail", text)
                return text;
            })*/
            /*return email.replaceAll(/<[\w-\.]+@([\w-]+\.)+[\w-]{2,4}>/gm, substring => {
                return "<span style='display: none'>" + substring.substr(1, substring.length - 2) + "</span>"
            })*/
        },
        replaceCidImages(email){
            const regex = /(?<=src=[\",\']).+?(?=[\",\'])/gm;
            const parts = JSON.parse(email.payload).parts;
            return email.body.replaceAll(regex, (substring, args) => {
                if (substring.startsWith("cid")) {
                    let cid = substring.substr(4);
                    let image = this.getImage(cid, parts)
                    if (image) {
                        let attachmentId = image.body.attachmentId;
                        return process.env.VUE_APP_API_ENDPOINT + "google/gmail/message/" + email.id + "/image/" + attachmentId
                    }
                }
                return substring;
            });
        },
        getImage(cid, parts){
            const search = (part) => {
                let header = part.headers.find(header => header.name === "Content-ID")
                if (header != null && header.value === "<"+cid+">") {
                    return part;
                }

                if(part.parts){
                    for (const subpart of part.parts) {
                        const res = search(subpart);

                        if (res) {
                            return res;
                        }
                    }
                }
            };

            for(let part of parts){
                let result = search(part);
                if(result){
                    return result;
                }
            }
        },
        novaMensagem(){
            this.$refs.novaMensagemDialog.openDialog();
        },
        downloadAttachment(originMessageId, messageId, attachment){
            this.$axios.get("/google/gmail/messages/" + originMessageId + "/attachment/" + messageId + "/" + attachment.attachmentId, {responseType: 'blob'})
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    fileLink.setAttribute('download', attachment.filename);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                })
        },
        viewAttachment(originMessageId, messageId, attachment){
            this.$axios.get("/google/gmail/messages/" + originMessageId + "/attachment/" + messageId + "/" + attachment.attachmentId, {responseType: 'blob'})
                .then((response) => {
                    let options = {
                        type: attachment.mimeType
                    };

                    let blob = new Blob([response.data], options)
                    let fileURL = URL.createObjectURL(blob);
                    //window.open(fileURL)
                    this.viewAttachmentDialog = true;
                    this.iframeSrc = fileURL;
                }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível abrir o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
    }
}
</script>

<style>
    .email-content pre{
        white-space: pre-wrap;
    }

    .email-content img{
        max-width: 100% !important;
        height: auto !important;
    }

    .v-list-item-unreaded{
        background-color: #efefef;
    }
    .v-list-item-unreaded::after{
        width: 4px;
        content: "";
        display: inline-block;
        height: 67px;
        position: absolute;
        left: 0;
        background-color: #4CAF50;
    }

</style>